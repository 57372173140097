module services {
    export module costing {

        export class costParameterCustomerProductCategoryService implements interfaces.costing.ICostParameterCustomerProductCategoryService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getCustomerProductCategoryList(): ng.resource.IResourceClass<interfaces.costing.ICostCustomerProductCategory> {
                return this.$resource<interfaces.costing.ICostCustomerProductCategory>(this.ENV.DSP_URL + "CostParameterCustomerProductCategory/GetCustomerProductCategoryList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    customerId: '@customerId',
                    consigneeId: '@consigneeId',
                    productCategoryId: '@productCategoryId',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveCustomerProductCategory(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterCustomerProductCategory/SaveCustomerProductCategory", {
                });
            }

            removeCustomerProductCategory(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterCustomerProductCategory/RemoveCustomerProductCategory", {
                    ccpId: '@ccpId',
                });
            }


        }

    }

    angular.module("app").service("costParameterCustomerProductCategoryService", services.costing.costParameterCustomerProductCategoryService);
}